
const $ = require('jquery');
global.$ = global.jQuery = $;

import whatInput from 'what-input';
import { Foundation } from 'foundation-sites/js/foundation';

Foundation.Interchange.SPECIAL_QUERIES['smallplus'] = 'only screen and (min-width: 22em)';
$(document).foundation();

/**
 * Hide/show passowrd
 */
[].forEach.call( document.querySelectorAll('form .mask'), function(el) { 
    el.addEventListener('click', function(e) {
        var elm = document.getElementById(el.dataset.mask);
        var toType = el.dataset.maskType;        
        el.setAttribute('data-mask-type', elm.getAttribute('type'));
        el.classList.toggle('on');
        elm.setAttribute('type', toType);
    });
});

/* Back to top button */
window.onscroll = function() { 
    var pageOffset = document.documentElement.scrollTop || document.body.scrollTop; 
    var butBackToTop = document.getElementById('but-backToTop');
    if (butBackToTop) { 
       butBackToTop.style.visibility = (pageOffset > 150)? 'visible' : 'hidden';
    }    
};

